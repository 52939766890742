export function trackClick(section, content, content_type='botón') {
    let flow = document.location.pathname;
    if (flow === '/') flow = 'home';
    flow = flow.replace(/\//g, ' ').trim();

    dataLayer.push({
        event: 'select_content',
        section: section,
        selected_content: content,
        content_type: content_type,
        flow: flow
    });
}